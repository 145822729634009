var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-8", attrs: { align: "justify" } },
        [
          _vm._v(" Ciao " + _vm._s(_vm.operatore) + ","),
          _c("br"),
          _c("br"),
          _vm._v(
            " I dati inseriti nel questionario di Analisi del Rischio non hanno restituito proposte che rispondono ai parametri richiesti. Bisogna pertanto procedere con una emissione manuale della pratica. Questa sezione è in fase di sviluppo e ti chiediamo quindi di inviare una email ai responsabili della sezione Rami per fare una richiesta di quotazione in Compagnia. Stiamo lavorando per automatizzare questa sezione del programma che sarà disponibile quanto prima. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Grazie "),
        ]
      ),
      _c("div", { staticClass: "col-12 col-md-8", attrs: { align: "right" } }, [
        _vm._v(" Il Team di sviluppo "),
      ]),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
      [
        _c("QQButton", {
          attrs: {
            label: "FINE",
            color: "blue-grey",
            icon: "mdi-check-outline",
            size: "md",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onNuovoClicked.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("br"),
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Richiesta di quotazione in Compagnia"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }